import { UIBusinessGroupGiftCardDebtAccount } from 'admin/business-groups/edit';
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { combineMissingAccounts, missingGiftCardNkAccountsToMessage, missingPaymentTypesToMessage, missingProductCategoriesToMessage, missingProductIdsToMessage, missingVatsToMessage } from 'bookkeeping/missing-accounts';
import { BusinessGroup, BusinessGroupGiftCardDebtAccount, Client, ClientIntegration, ClientTransferTarget, ClientTransferTargetAndDependenciesRequest, ClientTransferTargetPaymentType, ClientTransferTargetProductCategory, ClientTransferTargetUpdateRequest, FennoaVat, IdName, IntegrationMessageExtended, IntegrationMessageMissingAccounts, LocalizedPaymentType, MyHttpApi, PosPaymentType, strategyList } from 'utils/api';

interface CttDependenciesRequestResponseUI {
  cttPaymentType: UICttPaymentType[];
  cttProductCategory: UICttProductCategory[];
}
interface UICttProductCategory {
  productCategory?: string,
  account?: number,
  productId?: string,
  clientSpecific: string,
}

interface UICttPaymentType {
  paymentType?: PosPaymentType,
  account?: number,
  clientSpecific: string,
}

@autoinject
export class BookkeepingSettingsTargetEdit {
  private clientList: Client[] = [];
  private fullPaymentTypeList: LocalizedPaymentType[] = [];
  private target: ClientTransferTargetUpdateRequest = {
    active: true,
    delete: false,
    fennoaTempActive: false,
    name: "",
    strategy: 'PROCOUNTOR_ACCOUNTING',
    paperTransmissionTypeId: "",
    emailTransmissionTypeId: "",
    einvoiceTransmissionTypeId: "",
  };
  strategyList = strategyList;
  private cttDependencies: CttDependenciesRequestResponseUI = {
    cttPaymentType: [],
    cttProductCategory: [],
  };

  private targetClient: IdName[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transmissionTypes?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accounts?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private companies?: any[];
  private canAdmin = false;
  private businessGroupList: BusinessGroup[] = [];
  private integrationMismatchList: ClientIntegration[] = [];
  private integrationError?: string;
  private missingVats?: string;
  private missingPaymentTypes?: string;
  private missingProductCategories?: string;
  private missingProductIds?: string;
  private missingGiftCardNkAccounts?: string;
  private fennoaVat: FennoaVat[] = [];
  private missingAccounts?: IntegrationMessageMissingAccounts;
  private clientBusinessGroup: UIBusinessGroupGiftCardDebtAccount[] = [];

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.canAdmin = this.api.session?.canAdmin || false;
    [this.clientList, this.fullPaymentTypeList, this.businessGroupList, this.fennoaVat] = await Promise.all([
      this.api.clientList(),
      this.api.clientTransferTargetFullPaymentTypeList(),
      this.api.businessGroupList(),
      this.api.clientTransferTargetGetFennoaVat(),
    ]);
    this.fullPaymentTypeList = this.fullPaymentTypeList.map(pt => {
      return {
        ...pt, name: this.i18n.tr("PaymentType." + pt.id),
      };
    });
    if (params.id) {
      let id = parseInt(params.id);
      let [target, targetClient, cttDependencies, integrationMessagesOfMissingAccounts] = await Promise.all([
        this.api.clientTransferTargetByIdForAdministration({ id }),
        this.api.clientTransferTargetListClient({ id }),
        this.api.clientTransferTargetDependencies({ targetId: id }),
        this.api.integrationMessageList({
          targetId: id,
          reason: 'MISSING_ACCOUNTS',
          showAlsoHidden: true,
        }),
      ]);
      this.target = {
        ...target,
        paperTransmissionTypeId: target.paperTransmissionTypeId.join(","),
        emailTransmissionTypeId: target.emailTransmissionTypeId.join(","),
        einvoiceTransmissionTypeId: target.einvoiceTransmissionTypeId.join(","),
        delete: !!target.deleteTime
      };
      this.targetClient = targetClient;
      this.cttDependencies = {
        cttPaymentType: this.buildUIPaymentType(cttDependencies.cttPaymentType),
        cttProductCategory: this.buildUIProductCategory(cttDependencies.cttProductCategory),
      };
      this.buildFilteredMissingAccounts(integrationMessagesOfMissingAccounts);
    }
  }

  // Combine the missing accounts to one set, filter away already added paymentTyupes + productCategories and assing to private variable
  buildFilteredMissingAccounts(integrationMessagesOfMissingAccounts: IntegrationMessageExtended[]) {
    let ma = combineMissingAccounts(integrationMessagesOfMissingAccounts);
    ma.paymentTypes = ma.paymentTypes.filter(pt => !this.cttDependencies.cttPaymentType.find(cttPt => cttPt.paymentType == pt && cttPt.account));
    ma.productCategories = ma.productCategories.filter(pc => !this.cttDependencies.cttProductCategory.find(cttPc => cttPc.productCategory == pc && cttPc.account));
    this.missingAccounts = ma;
    this.handleMissingAccounts();

  }

  handleMissingAccounts() {
    if (!this.missingAccounts) {
      return;
    }
    // filter away, if we have defined with commont account or account AFTER the IntegrationMessage
    // const paymentTypes = ma.paymentTypes.filter(pt => !this.cttDependencies.cttPaymentType.find(cttPt => cttPt.paymentType == pt && cttPt.account));
    // const productCategories = ma.productCategories.filter(pc => !this.cttDependencies.cttProductCategory.find(cttPc => cttPc.productCategory == pc && cttPc.account));
    this.missingVats = missingVatsToMessage(this.i18n, this.missingAccounts.vats);
    this.missingPaymentTypes = missingPaymentTypesToMessage(this.i18n, this.missingAccounts.paymentTypes);
    this.missingProductCategories = missingProductCategoriesToMessage(this.i18n, this.missingAccounts.productCategories);
    this.missingProductIds = missingProductIdsToMessage(this.i18n, this.missingAccounts.productIds);
    this.missingGiftCardNkAccounts = missingGiftCardNkAccountsToMessage(this.i18n, this.missingAccounts.giftCardNkAccounts);
  }

  @computedFrom("target.adminClientId", "target.adminBusinessGroupId")
  get adminObserver() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.checkForIntegrationMismatch();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.getBusinessGroupGiftCardDebtAccounts();
    return "";
  }

  async checkForIntegrationMismatch() {
    if (this.api.session?.canAdmin && this.target.id) {
      this.integrationMismatchList = await this.api.clientIntegrationListConfigMismatch({
        targetId: this.target.id,
        adminBusinessGroupId: this.target.adminBusinessGroupId,
        adminClientId: this.target.adminClientId,
      });
      if (!this.integrationMismatchList.length) {
        this.integrationError = undefined;
        return;
      }
      let error = this.i18n.tr("client-sale.configurationError") + "\n";
      this.integrationMismatchList.forEach(ci => {
        const client = this.clientList.find(c => c.id === ci.clientId);
        if (!client) {
          error += `#${ci.clientId}\n`;
          return;
        }
        error += `${client.nickname}\n`;
      });
      this.integrationError = error;
    }
  }

  async getBusinessGroupGiftCardDebtAccounts() {
    if (this.target.adminBusinessGroupId) {
      let id = this.target.adminBusinessGroupId;
      let [clientBusinessGroup, debtAccounts] = await Promise.all([
        this.api.clientListByBusinessGroupId({ id }),
        this.api.businessGroupListGiftCardDebtAccountById({ id }),
      ]);

      this.clientBusinessGroup = clientBusinessGroup.map(cbg => {
        const account = debtAccounts.find(da => da.clientId === cbg.id);
        return {
          id: cbg.id,
          name: cbg.name,
          creditAccount: account?.creditAccount,
          debitAccount: account?.debitAccount,
        };
      });
    } else {
      this.clientBusinessGroup = [];
    }
  }
  /*
   * Both buildUI... have similar use cases. They operate on productCategory or paymentType
   * We take distinct array of existing values and present them. If value has client-specific values
   * then we do not allow removal of that row. Even thou that would be only visual thing (SS will save only common ones)
   * Thus, the values are not required for items, that are client-specific
   */

  buildUIPaymentType(cttPaymentType: ClientTransferTargetPaymentType[]) {
    let distinctPaymentTypes: PosPaymentType[] = [];
    let res: UICttPaymentType[] = [];
    cttPaymentType.forEach(x => {
      const paymentType = x.paymentType;
      if (distinctPaymentTypes.includes(paymentType)) {
        return;
      }
      distinctPaymentTypes.push(paymentType);
      const common = cttPaymentType.find(pt => pt.clientId === undefined && pt.paymentType == paymentType);
      let item: UICttPaymentType = { ...common, paymentType, clientSpecific: "" };
      cttPaymentType.filter(pt => pt.paymentType === paymentType && pt.clientId !== undefined).forEach(pt => {
        const client = this.clientList.find(c => c.id === pt.clientId);
        if (!client) {
          console.warn(`PT: Could not find client for #${pt.clientId}`);
        }
        if (item.clientSpecific.length) {
          item.clientSpecific += "\n";
        }
        item.clientSpecific += `${client?.nickname || pt.clientId}: ${pt.account}`;
      });
      res.push(item);
    });
    return res;
  }

  buildUIProductCategory(cttProductCategory: ClientTransferTargetProductCategory[]) {
    let distinctProductCategories: string[] = [];
    let res: UICttProductCategory[] = [];
    cttProductCategory.forEach(x => {
      const productCategory = x.productCategory;
      if (distinctProductCategories.includes(productCategory)) {
        return;
      }
      distinctProductCategories.push(productCategory);
      const common = cttProductCategory.find(pc => pc.clientId === undefined && pc.productCategory == productCategory);
      let item: UICttProductCategory = { ...common, productCategory, clientSpecific: "" };
      cttProductCategory.filter(pc => pc.productCategory === productCategory && pc.clientId !== undefined).forEach(pc => {
        const client = this.clientList.find(c => c.id === pc.clientId);
        if (!client) {
          console.warn(`PC: Could not find client for #${pc.clientId}`);
        }
        if (item.clientSpecific.length) {
          item.clientSpecific += "\n";
        }
        item.clientSpecific += `${client?.nickname || pc.clientId}: ${pc.account}`;
        if (pc.productId) {
          item.clientSpecific += ` / ${pc.productId}`;
        }
      });
      res.push(item);
    });
    return res;
  }

  async showTalenomAccounts(fetch: boolean) {
    if (fetch && this.target.id) {
      this.accounts = await this.api.talenomGetAccounts({
        targetId: this.target.id,
      });
    } else {
      this.accounts = undefined;
    }
  }

  hideProducts() {
    this.products = undefined;
  }

  async showProcountorProducts() {
    if (this.target.id) {
      this.products = await this.api.procountorGetProducts({
        targetId: this.target.id,
      });
    }
  }

  async showNetvisorProducts() {
    if (this.target.id) {
      this.products = await this.api.netvisorGetProducts({
        targetId: this.target.id,
      });
    }
  }

  async showFivaldiProducts() {
    if (this.target.id) {
      this.products = await this.api.fivaldiProductList({ targetId: this.target.id, });
    }
  }

  async showFivaldiCompanies(fetch: boolean) {
    if (fetch && this.target.username && this.api.session?.canAdmin) {
      this.companies = await this.api.fivaldiCompanyListAdmin({ customerId: this.target.username, });
    } else if (fetch && this.target.id) {
      this.companies = await this.api.fivaldiCompanyList({ targetId: this.target.id, });
    } else {
      this.companies = undefined;
    }
  }

  async showFivaldiAccounts(fetch: boolean) {
    if (fetch && this.target.id) {
      this.accounts = await this.api.fivaldiAccountList({ targetId: this.target.id, });
    } else {
      this.accounts = undefined;
    }
  }

  async showFivaldiTransmissionTypes(fetch: boolean) {
    if (fetch && this.target.id) {
      let invoicingDetails = await this.api.fivaldiCompanyInvoicingDetails({
        targetId: this.target.id,
      });
      this.transmissionTypes = invoicingDetails.transmissionTypeDTOList;
    } else {
      this.transmissionTypes = undefined;
    }
  }

  async showFivaldiCustomers(fetch: boolean) {
    if (fetch) {
      this.customers = await this.api.fivaldiCustomerList();
    } else {
      this.customers = undefined;
    }
  }

  async _save() {
    let req: ClientTransferTargetAndDependenciesRequest = {
      target: this.target,
      dependencies: {
        cttPaymentType: [],
        cttProductCategory: [],
      }
    };
    for (let pt of this.cttDependencies.cttPaymentType) {
      if (pt.account && pt.paymentType) {
        req.dependencies.cttPaymentType.push({
          paymentType: pt.paymentType,
          account: pt.account,
        });
      }
    }

    for (let pc of this.cttDependencies.cttProductCategory) {
      if (pc.account && pc.productCategory) {
        req.dependencies.cttProductCategory.push({
          productCategory: pc.productCategory,
          account: pc.account,
          productId: (pc.productId?.length ? pc.productId : undefined),
        });
      }
    }

    await this.api.clientTransferTargetUpdate(req);

    if (this.target.adminBusinessGroupId) {
      let debtAccounts: BusinessGroupGiftCardDebtAccount[] = [];
      for (let cbg of this.clientBusinessGroup) {
        if (cbg.creditAccount && cbg.debitAccount) {
          debtAccounts.push({
            businessGroupId: this.target.adminBusinessGroupId,
            clientId: cbg.id,
            creditAccount: cbg.creditAccount,
            debitAccount: cbg.debitAccount,
          });
        }
      }

      await this.api.businessGroupUpdateGiftCardDebtAccounts({
        id: this.target.adminBusinessGroupId,
        giftCardDebtAccounts: debtAccounts,
      });
    }
  }

  async save() {
    await this._save();
    this.router.navigateToRoute("bookkeeping/settings/target-list");
  }

  async delete() {
    if (!this.target.id || !confirm(this.i18n.tr("client-sale.confirmDelete"))) {
      return;
    }
    await this.api.clientTransferTargetDelete({ id: this.target.id });
    this.router.navigateToRoute("bookkeeping/settings/target-list");
  }

  addAllMissingPaymentTypes() {
    if (this.missingAccounts) {
      this.missingAccounts.paymentTypes.forEach(pt => this.addPaymentType(pt));
      this.missingPaymentTypes = undefined;
    }
  }

  addPaymentType(pt?: PosPaymentType) {
    this.cttDependencies.cttPaymentType.push({ paymentType: pt, clientSpecific: "" });
  }

  deletePaymentType(x: UICttPaymentType) {
    if (x.clientSpecific.length) {
      x.account = undefined;
    } else {
      this.cttDependencies.cttPaymentType = this.cttDependencies.cttPaymentType.filter(pt => pt !== x);
    }
  }

  addAllMissingProductCategories() {
    if (this.missingAccounts) {
      this.missingAccounts.productCategories.forEach(pc => this.addProductCategory(pc));
      this.missingProductCategories = undefined;
    }
  }

  addProductCategory(pc?: string) {
    this.cttDependencies.cttProductCategory.push({ productCategory: pc, clientSpecific: "" });
  }

  deleteProductCategory(x: UICttProductCategory) {
    if (x.clientSpecific.length) {
      x.account = undefined;
      x.productId = undefined;
    } else {
      this.cttDependencies.cttProductCategory = this.cttDependencies.cttProductCategory.filter(pc => pc !== x);
    }
  }

  @computedFrom("clientList")
  get displayClientList() {
    return this.targetClient.map(x => x.name).join(", ") || "-";
  }

  async getRefreshToken(target: ClientTransferTarget) {
    /* Redirect to procountor's oauth API */
    let url = this.api.procountorLoginUrl({
      targetId: target.id,
      url: window.location.href,
    });
    window.location.href = url;
  }
}
