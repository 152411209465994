// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#memoErrors div {
  background-color: #ffcccb;
  color: black;
  border: 1px solid #2878F0;
  padding: 8px 6px 8px 6px;
  width: 90%;
  font-weight: bold;
}
div#memoErrors div span {
  color: black;
  padding-right: 8px;
}
h2 {
  padding: 0.42em 0 12px 16px;
  margin-bottom: 8px;
  height: 2em;
  font-size: 1.4em;
}
td.money {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/bookkeeping/report/audit-memo.less"],"names":[],"mappings":"AAGA;EAEI,yBAAA;EACA,YAAA;EACA,yBAAA;EACA,wBAAA;EACA,UAAA;EACA,iBAAA;AAHJ;AAJA;EASM,YAAA;EACA,kBAAA;AAFN;AAOA;EACE,2BAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AALF;AAQA;EACE,iBAAA;AANF","sourcesContent":["\n@import \"styles/variables.less\";\n\ndiv#memoErrors {\n  div {\n    background-color: #ffcccb;\n    color: black;\n    border: 1px solid @tulopos-brand-border-color;\n    padding: 8px 6px 8px 6px; \n    width: 90%;\n    font-weight: bold;\n    span {\n      color: black;\n      padding-right: 8px;\n    }\n  }\n}\n\nh2 {\n  padding: 0.42em 0 12px 16px;\n  margin-bottom: 8px;\n  height: 2em;\n  font-size: 1.4em;\n}\n\ntd.money {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
