import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ClientSettingsUpdateRequest, MyHttpApi } from 'utils/api';

@autoinject
export class BookkeepingSettingsSourceEdit {
  private client?: ClientSettingsUpdateRequest;

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string }) {
    let clientId = parseInt(params.id);
    this.client = await this.api.clientById({ id: clientId });
  }

  async save() {
    await this.api.clientSettingsUpdate(this.client!);
    this.router.navigateToRoute("bookkeeping/settings/source-list");
  }

  @computedFrom("api.session.canIntegrationSettings")
  get readOnly() {
    return !this.api.session?.canIntegrationSettings;
  }
}
