import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { missingAccountsToMessage } from 'bookkeeping/missing-accounts';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { FormatDateValueConverter } from 'resources/value-converters/format-date';
import { Client, ClientTransferTarget, ClientTransferTargetMessage, IntegrationMessageAction, IntegrationMessageExtended, IntegrationMessageLevel, IntegrationMessageReason, IntegrationStrategy, MyHttpApi, ParamsType, strategyList } from 'utils/api';
import { DatesToRange } from 'utils/dates-to-range';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

interface UIIntegrationMessage extends IntegrationMessageExtended {
  strategy?: string;
  addToTarget?: string;
  addToIntegration?: string;
  message: string;
  rowClass?: string;
  delete?: string;
}

@autoinject
export class BookkeepingSettingsIntegrationMessageList extends BaseRoute {
  private integrationMessageList: UIIntegrationMessage[] = [];
  private strategyList: { [key: string]: { name: string; }; } = {};
  private clients: { [key: number]: { name: string; }; } = {};
  private clientList: Client[] = [];
  private targetList: ClientTransferTarget[] = [];
  private targetFullList: ClientTransferTarget[] = [];
  private localStrategyList: { id: string, name: string; }[] = strategyList;
  private formatDate = new FormatDateValueConverter;

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.integrationMessageList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private strategy?: IntegrationStrategy;
  @observable({ changeHandler: "search" })
  private level?: IntegrationMessageLevel;
  @observable({ changeHandler: "search" })
  private action?: IntegrationMessageAction;
  @observable({ changeHandler: "search" })
  private reason?: IntegrationMessageReason;
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private targetId?: number;
  @observable({ changeHandler: "search" })
  private errorMessage?: string;
  @observable({ changeHandler: "search" })
  private showAlsoHidden = false;

  private fieldList: FieldSpec[] = [
    { key: "createTime", header: "common.date", type: "date", cssClass: "not-clickable", },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: this.clients, cssClass: "not-clickable", lookupKey: "name", },
    { key: "strategy", header: "client-sale.target", type: "lookup", lookupData: this.strategyList, cssClass: "not-clickable", lookupKey: "name", },
    { key: "message", header: "integration-message.message", type: "text", cssClass: "not-clickable prewrapped" },
    { key: "level", header: "integration-message.level", type: "enum", cssClass: "not-clickable", enum: "IntegrationMessageLevel" },
    { key: "addToTarget", header: "integration-message.addToTarget", type: "text", cssClass: "link" },
    { key: "addToIntegration", header: "integration-message.addToIntegration", type: "text", cssClass: "link" },
  ];

  private cttm?: ClientTransferTargetMessage;
  private showModal = false;
  private showCttm = false; // Do not display cttm for *FETCH-rows
  private modalContent = "";

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private datesToRange: DatesToRange) {
    super();
    for (let s of strategyList) {
      this.strategyList[s.id] = s;
    }
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "strategy": String,
      "level": String,
      "action": String,
      "reason": String,
      "startDate": Date,
      "endDate": Date,
      "targetId": Number,
      "errorMessage": String,
      "showAlsoHidden": Boolean,
    };
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    if (!this.startDate) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1, 1);
      this.startDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    }
    [this.clientList, this.targetList, this.targetFullList] = await Promise.all([
      this.api.clientList(),
      this.api.clientTransferTargetListAdministrable(), // Show link to target-edit only if those are on this list
      this.api.clientTransferTargetOwnList(),
    ]);
    this.clientList.forEach(x => this.clients[x.id] = { name: x.nickname });
    if (this.api.session?.canSuperuser) {
      this.fieldList.push({ key: "delete", header: "common.delete", type: "text", cssClass: "link" });
    }
    await this.search();
  }

  buildQueryParams() {
    return {
      clientId: this.clientId,
      strategy: this.strategy,
      level: this.level,
      action: this.action,
      reason: this.reason,
      startDate: this.startDate,
      endDate: this.endDate,
      targetId: this.targetId,
      errorMessage: this.errorMessage ? this.errorMessage : undefined,
      showAlsoHidden: this.showAlsoHidden,
    };
  }

  async search() {
    await this.ecs.search();
  }

  @computedFrom("showAlsoHidden")
  get clientListDynamic() {
    if (this.api.session?.canAdmin && !this.showAlsoHidden) {
      return this.clientList.filter(c => !c.hidden);
    }
    return this.clientList;
  }

  applyData(data: IntegrationMessageExtended[]) {
    this.integrationMessageList = data.map(x => {
      let im: UIIntegrationMessage = { ...x, message: "" };
      let meta = {
        minAccountingId: im.minAccountingId,
        count: im.count,
        bookkeepingId: im.bookkeepingId,
        businessDate: im.businessDate ? this.formatDate.toView(im.businessDate) : this.i18n.tr("common.empty"),
        startBusinessDate: im.startBusinessDate ? this.formatDate.toView(im.startBusinessDate) : this.i18n.tr("common.empty"),
        endBusinessDate: im.endBusinessDate ? this.formatDate.toView(im.endBusinessDate) : this.i18n.tr("common.empty"),
      };
      let trKey = "integration-message." + im.action + "-" + im.reason;
      if (im.action === 'ACCOUNTING_SEND' && !im.startBusinessDate && im.reason === "OK") {
        // * Procountor ledger, not having dates but CTTM
        trKey += "-Procountor";
      }
      im.message = this.i18n.tr(trKey, meta);
      if (im.targetId) {
        const strategyId = this.targetFullList.find(t => t.id === im.targetId)?.strategy;
        if (strategyId) {
          im.strategy = this.strategyList[strategyId].name;
        }
        if (im.reason === 'MISSING_ACCOUNTS') {
          im.message += "\n\n" + missingAccountsToMessage(this.i18n, x.missingAccounts);
          im.addToIntegration = this.i18n.tr("common.goto");
          if (this.targetList.find(t => t.id === im.targetId)) {
            im.addToTarget = this.i18n.tr("common.goto");
          }
        } else if (im.reason === 'NOT_ALL_DATA' && x.notAllData.length) {
          im.message += "\n\n";
          x.notAllData.forEach(nad => im.message += nad.clientNickName + ": " + this.datesToRange.transform([...nad.missingDates]) + "\n");
        }
      }
      if (im.level === 'ERROR') {
        im.delete = this.i18n.tr("common.delete");
      }
      return im;
    });
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async rowCall(key: string, row: UIIntegrationMessage) {
    if (key === "delete" && row.delete) {
      await this.api.integrationMessageDelete({ id: row.id });
      await this.search();
      return;
    }
    if (row.reason === "MISSING_ACCOUNTS") {
      if (key === "addToTarget" && this.targetList.find(t => t.id === row.targetId)) {
        this.router.navigateToRoute("bookkeeping/settings/target-edit", { id: "" + row.targetId });
      } else if (key === "addToIntegration") {
        this.router.navigateToRoute("bookkeeping/settings/client-integration-edit", { id: "" + row.clientId });
      }
    } else if (this.api.session?.canAdmin && ["message", "level"].includes(key)) {
      this.showCttm = false;
      if (row.reason === "SEND_FAILED") {
        this.showCttm = true;
        if (row.cttmId) {
          if (!this.cttm || this.cttm.id != row.cttmId) {
            this.cttm = await this.api.clientSaleBillingCttm({ id: row.cttmId });
          }
        }
        this.modalContent = row.extraInfo || "";
        this.showModal = true;
      } else if (row.exception) {
        this.modalContent = row.exception;
        this.showModal = true;
      }
    }
  }
}
