import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { combineMissingAccounts, missingGiftCardNkAccountsToMessage, missingPaymentTypesToMessage, missingProductCategoriesToMessage, missingProductIdsToMessage, missingVatsToMessage } from 'bookkeeping/missing-accounts';
import { BusinessGroupGiftCardDebtListResponse, Client, ClientIntegration, ClientIntegrationAndDependenciesRequest, ClientIntegrationUpdateRequest, ClientTransferTarget, ClientTransferTargetDependenciesResponse, ClientTransferTargetPaymentType, ClientTransferTargetProductCategory, FennoaVat, IdName, IntegrationMessageExtended, IntegrationMessageMissingAccounts, LocalizedPaymentType, MyHttpApi, PosPaymentType, strategyList } from 'utils/api';

interface ClientTransferTargetDependenciesUI {
  cttPaymentType: UICttPaymentType[],
  cttProductCategory: UICttProductCategory[],
}
interface UICttProductCategory {
  clientId?: number;
  productCategory?: string;
  account?: number;
  productId?: string;
  commonAccount?: number;
  commonProductId?: string;
}

interface UICttPaymentType {
  clientId?: number;
  paymentType?: PosPaymentType;
  account?: number;
  commonAccount?: number;
}

@autoinject
export class BookkeepingSettingsClientIntegrationEdit {
  private clientList: Client[] = [];
  private fullPaymentTypeList: LocalizedPaymentType[] = [];

  @observable({ changeHandler: "reloadTarget" })
  private targetId?: number;

  private targetList: ClientTransferTarget[] = [];
  strategyList = strategyList;
  private client?: Client;
  private clientIntegration: ClientIntegrationUpdateRequest = {
    clientId: 0,
    targetId: 0,
    paymentTime: 14,
    autoSendCompletedInvoices: false,
    autoSendCompletedInvoicesToCustomer: false,
    completionNotificationLanguage: 'FI',
  };
  private fullCttDependencies?: ClientTransferTargetDependenciesResponse;
  private cttDependencies: ClientTransferTargetDependenciesUI = {
    cttPaymentType: [], cttProductCategory: []
  };
  private targetClient: IdName[] = [];
  private allClientIntegrations: ClientIntegration[] = [];
  /** used to check if we have a row, as clientIntegration.id does not exists. */
  private exists = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private accounts?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dimensions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private voucherTypes?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fennoaCodeDimensions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private products?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private paymentTerms?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private postingGroups?: any[];
  private clientId = 0; // always present
  private target?: ClientTransferTarget;
  private missingVats?: string;
  private missingPaymentTypes?: string;
  private missingProductCategories?: string;
  private missingProductIds?: string;
  private missingGiftCardNkAccounts?: string;
  private fennoaVat: FennoaVat[] = [];
  private debtAccounts: BusinessGroupGiftCardDebtListResponse[] = [];
  private missingAccounts?: IntegrationMessageMissingAccounts;
  private canAdmin = false;

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  /** Do not use computedFrom here, every time the property is touched. Observable is better here */
  async reloadTarget() {
    // empty all possible gotten data on target change
    this.dimensions = undefined;
    this.fennoaCodeDimensions = undefined;
    this.products = undefined;
    this.accounts = undefined;

    // load depenencies and recalc pc and pt
    this.cttDependencies.cttProductCategory = this.buildUIProductCategory(this.fullCttDependencies?.cttProductCategory || []);
    this.cttDependencies.cttPaymentType = this.buildUIPaymentType(this.fullCttDependencies?.cttPaymentType || []);

    // build possible clients to have in productCategory / paymentType
    let targetClient: IdName[] = [{ id: this.client?.id || 0, name: this.client?.nickname || "" }];
    let filteredIntegrations = this.allClientIntegrations.filter(ci => ci.targetId == this.targetId) || [];
    filteredIntegrations.forEach(ci => {
      if (ci.clientId != this.client?.id) {
        let client = this.clientList.find(c => c.id === ci.clientId);
        targetClient.push({ id: ci.clientId, name: client?.nickname || "" });
      }
    });
    this.targetClient = targetClient;
    this.target = this.targetList.find(t => t.id === this.targetId);
    this.clientIntegration.fennoaStatementSeriesCode = this.target?.strategy === 'FENNOA_ACCOUNTING' ? "CA" : undefined;
  }

  /** Both buildUI... have similar use cases. They will fetch common and this client-specific config and display them.
   * If we have common config, that config can't be deleted from list and is not required to be client-specific */
  buildUIPaymentType(cttPaymentType: ClientTransferTargetPaymentType[]) {
    let filteredPaymentTypes = cttPaymentType.filter(pt => pt.targetId === this.targetId && (!pt.clientId || pt.clientId === this.clientId));
    let distinctPaymentTypes: PosPaymentType[] = [];
    let res: UICttPaymentType[] = [];
    filteredPaymentTypes.forEach(x => {
      const paymentType = x.paymentType;
      if (distinctPaymentTypes.includes(paymentType)) {
        return;
      }
      distinctPaymentTypes.push(paymentType);
      const common = filteredPaymentTypes.find(pt => pt.clientId === undefined && pt.paymentType == paymentType);
      const clientSpecific = filteredPaymentTypes.find(pt => pt.clientId === this.clientId && pt.paymentType == paymentType);
      let item: UICttPaymentType = {
        paymentType,
        account: clientSpecific?.account,
        commonAccount: common?.account,
        clientId: this.clientId,
      };
      res.push(item);
    });
    return res;
  }

  buildUIProductCategory(cttProductCategory: ClientTransferTargetProductCategory[]) {
    let filteredProductCategories = cttProductCategory.filter(pt => pt.targetId === this.targetId && (pt.clientId === undefined || pt.clientId === this.clientId));
    let distinctProductCategories: string[] = [];
    let res: UICttProductCategory[] = [];
    filteredProductCategories.forEach(x => {
      const productCategory = x.productCategory;
      if (distinctProductCategories.includes(productCategory)) {
        return;
      }
      distinctProductCategories.push(productCategory);
      const common = filteredProductCategories.find(pc => pc.clientId === undefined && pc.productCategory == productCategory);
      const clientSpecific = filteredProductCategories.find(pc => pc.clientId === this.clientId && pc.productCategory == productCategory);
      let item: UICttProductCategory = {
        productCategory,
        commonAccount: common?.account,
        commonProductId: common?.productId,
        account: clientSpecific?.account,
        productId: clientSpecific?.productId,
        clientId: this.clientId,
      };
      res.push(item);
    });
    return res;
  }

  async activate(params: { id: string; }) {
    this.canAdmin = this.api.session?.canAdmin || false;
    this.clientId = parseInt(params.id);
    [this.client, this.targetList, this.fullPaymentTypeList, this.fullCttDependencies, this.allClientIntegrations, this.clientList, this.fennoaVat] = await Promise.all([
      this.api.clientById({ id: this.clientId }),
      this.api.clientTransferTargetListSelectable({ clientId: this.clientId }),
      this.api.clientTransferTargetFullPaymentTypeList(),
      this.api.clientTransferTargetDependencies({ targetId: undefined }),
      this.api.clientIntegrationList(),
      this.api.clientList(),
      this.api.clientTransferTargetGetFennoaVat(),
    ]);
    this.fullPaymentTypeList = this.fullPaymentTypeList.map(pt => {
      return {
        ...pt, name: this.i18n.tr("PaymentType." + pt.id),
      };
    });
    if (this.client.businessGroupId) {
      this.debtAccounts = await this.api.businessGroupReadOnlyListGiftCardDebtAccountByClientId({
        id: this.client.businessGroupId
      });
    }

    let [tmp, integrationMessagesOfMissingAccounts] = await Promise.all([
      this.api.clientIntegrationByClientId({ clientId: this.clientId }),
      this.api.integrationMessageList({
        clientId: this.clientId,
        reason: 'MISSING_ACCOUNTS',
        showAlsoHidden: true,
      }),
    ]);

    if (tmp) {
      this.clientIntegration = tmp;
      this.targetId = this.clientIntegration.targetId;
      this.exists = true;
      this.buildFilteredMissingAccounts(integrationMessagesOfMissingAccounts);
    }
  }

  // Combine the missing accounts to one set, filter away already added paymentTypes + productCategories and assing to private variable
  buildFilteredMissingAccounts(integrationMessagesOfMissingAccounts: IntegrationMessageExtended[]) {
    let ma = combineMissingAccounts(integrationMessagesOfMissingAccounts);
    ma.paymentTypes = ma.paymentTypes.filter(pt => !this.cttDependencies.cttPaymentType.find(cttPt => cttPt.paymentType === pt));
    ma.productCategories = ma.productCategories.filter(pc => !this.cttDependencies.cttProductCategory.find(cttPc => cttPc.productCategory == pc));
    this.missingAccounts = ma;
    this.handleMissingAccounts();

  }

  handleMissingAccounts() {
    if (!this.missingAccounts) {
      return;
    }
    this.missingVats = missingVatsToMessage(this.i18n, this.missingAccounts.vats);
    // filter away, if we have defined with commont account or account AFTER the IntegrationMessage
    this.missingPaymentTypes = missingPaymentTypesToMessage(this.i18n, this.missingAccounts.paymentTypes);
    this.missingProductCategories = missingProductCategoriesToMessage(this.i18n, this.missingAccounts.productCategories);
    this.missingProductIds = missingProductIdsToMessage(this.i18n, this.missingAccounts.productIds);
    this.missingGiftCardNkAccounts = missingGiftCardNkAccountsToMessage(this.i18n, this.missingAccounts.giftCardNkAccounts);
  }

  async showTalenomAccounts(fetch: boolean) {
    if (fetch && this.targetId) {
      this.accounts = await this.api.talenomGetAccounts({
        targetId: this.targetId,
      });
    } else {
      this.accounts = undefined;
    }
  }

  async showTalenomDimensions(fetch: boolean) {
    if (fetch && this.targetId) {
      this.dimensions = await this.api.talenomGetDimensions({
        targetId: this.targetId,
      });
    } else {
      this.dimensions = undefined;
    }
  }

  async showFivaldiProducts() {
    if (this.targetId) {
      this.products = await this.api.fivaldiProductList({ targetId: this.targetId, });
    }
  }

  async showFivaldiPaymentTerms(fetch: boolean) {
    if (fetch && this.targetId) {
      let invoicingDetails = await this.api.fivaldiCompanyInvoicingDetails({
        targetId: this.targetId,
      });
      this.paymentTerms = invoicingDetails.paymentTermDTOList;
    } else {
      this.paymentTerms = undefined;
    }
  }

  async showFivaldiPostingGroups(fetch: boolean) {
    if (fetch && this.targetId) {
      this.postingGroups = await this.api.fivaldiSalesPostingGroupList({
        targetId: this.targetId,
      });
    } else {
      this.postingGroups = undefined;
    }
  }

  async showFivaldiAccounts(fetch: boolean) {
    if (fetch && this.targetId) {
      this.accounts = await this.api.fivaldiAccountList({ targetId: this.targetId, });
    } else {
      this.accounts = undefined;
    }
  }

  async showFivaldiVoucherTypes(fetch: boolean) {
    if (fetch && this.targetId) {
      this.voucherTypes = await this.api.fivaldiVoucherTypeList({
        targetId: this.targetId,
      });
    } else {
      this.voucherTypes = undefined;
    }
  }

  async showProcountorDimensions(fetch: boolean) {
    if (fetch && this.targetId) {
      this.dimensions = await this.api.procountorGetDimensions({
        targetId: this.targetId,
      });
    } else {
      this.dimensions = undefined;
    }
  }

  async showProcountorProducts() {
    if (this.targetId) {
      this.products = await this.api.procountorGetProducts({
        targetId: this.targetId,
      });
    }
  }

  async showNetvisorProducts() {
    if (this.targetId) {
      this.products = await this.api.netvisorGetProducts({
        targetId: this.targetId,
      });
    }
  }

  async showFennoaTypeDimensions(fetch: boolean) {
    if (fetch && this.targetId) {
      this.dimensions = await this.api.fennoaGetTypeDimensions({
        targetId: this.targetId,
      });
    } else {
      this.dimensions = undefined;
    }
  }

  async showFennoaCodeDimensions(fetch: boolean) {
    if (fetch && this.targetId) {
      this.fennoaCodeDimensions = await this.api.fennoaGetCodeDimensions({
        targetId: this.targetId,
      });
    } else {
      this.fennoaCodeDimensions = undefined;
    }
  }

  hideProducts() {
    this.products = undefined;
  }

  async _save() {
    if (!this.targetId || !this.client) {
      return;
    }

    let req: ClientIntegrationAndDependenciesRequest = {
      integration: this.clientIntegration,
      dependencies: {
        cttPaymentType: [],
        cttProductCategory: [],
      },
    };
    for (let pt of this.cttDependencies.cttPaymentType) {
      if (pt.account && pt.paymentType) {
        req.dependencies.cttPaymentType.push({
          account: pt.account,
          paymentType: pt.paymentType,
          clientId: this.clientId,
        });
      }
    }

    for (let pc of this.cttDependencies.cttProductCategory) {
      if (pc.account && pc.productCategory) {
        req.dependencies.cttProductCategory.push({
          productCategory: pc.productCategory,
          account: pc.account,
          productId: (pc.productId?.length ? pc.productId : undefined),
          clientId: this.clientId,
        });
      }
    }
    req.integration.targetId = this.targetId;
    req.integration.clientId = this.client.id;
    req.integration.completionNotificationEmail = this.clientIntegration.completionNotificationEmail || undefined;
    await this.api.clientIntegrationUpdate(req);
  }
  async save() {
    await this._save();
    this.router.navigateToRoute("bookkeeping/settings/client-integration-list");
  }

  async delete() {
    if (!confirm(this.i18n.tr("client-sale.confirmDelete"))) {
      return;
    }
    await this.api.clientIntegrationDelete(<ClientIntegration>this.clientIntegration);
    this.router.navigateToRoute("bookkeeping/settings/client-integration-list");
  }

  addAllMissingPaymentTypes() {
    if (this.missingAccounts) {
      this.missingAccounts.paymentTypes.forEach(pt => this.addPaymentType(pt));
      this.missingPaymentTypes = undefined;
    }
  }

  addPaymentType(pt?: PosPaymentType) {
    this.cttDependencies.cttPaymentType.push({ paymentType: pt });
  }

  deletePaymentType(x: UICttPaymentType) {
    if (x.commonAccount) {
      x.account = undefined;
    } else {
      this.cttDependencies.cttPaymentType = this.cttDependencies.cttPaymentType.filter(p => p !== x);
    }
  }

  addAllMissingProductCategories() {
    if (this.missingAccounts) {
      this.missingAccounts.productCategories.forEach(pc => this.addProductCategory(pc));
      this.missingProductCategories = undefined;
    }
  }

  addProductCategory(pc?: string) {
    this.cttDependencies.cttProductCategory.push({ productCategory: pc });
  }

  deleteProductCategory(x: UICttProductCategory) {
    if (x.commonAccount || x.commonProductId) {
      x.account = undefined;
      x.productId = undefined;
    } else {
      this.cttDependencies.cttProductCategory = this.cttDependencies.cttProductCategory.filter(p => p !== x);
    }
  }
}
