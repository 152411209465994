import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, ClientIntegration, ClientTransferTarget, IdName, MyHttpApi, strategyList } from 'utils/api';

@autoinject
export class BookkeepingSettingsTargetList {
  private targetList: IdName[] = [];
  private strategy: { [key: string]: { name: string; }; } = {};
  private targetClient: { [key: number]: { name: string; }; } = {};
  private clientList: { [key: number]: { name: string; }; } = {};
  private businessGroupList: { [key: number]: { name: string; }; } = {};
  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "strategy", header: "client-sale.target", type: "lookup", lookupData: this.strategy, lookupKey: "name", },
    { key: "id", header: "unit.units", type: "lookup", lookupData: this.targetClient, lookupKey: "name", },
    { key: "adminClientId", header: "client-sale.adminClientId", type: "lookup", lookupData: this.clientList, lookupKey: "name", },
    { key: "adminBusinessGroupId", header: "client-sale.adminBusinessGroupId", type: "lookup", lookupData: this.businessGroupList, lookupKey: "name", },
    { key: "active", header: "client-sale.active", type: "boolean" },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    let [clientList, businessGroupList] = await Promise.all([
      this.api.clientList(),
      this.api.businessGroupList(),
    ]);
    clientList.forEach(x => this.clientList[x.id] = { name: x.nickname });
    businessGroupList.forEach(x => this.businessGroupList[x.id] = { name: x.name });
    for (let s of strategyList) {
      this.strategy[s.id] = s;
    }
    await this.refresh(clientList);
  }

  async refresh(clientList: Client[]) {
    let clientIntegrationList = await this.api.clientIntegrationList();
    this.clientList = clientList;
    let clientIntegrationHash: { [key: number]: ClientIntegration; } = {};
    for (let c of clientIntegrationList) {
      clientIntegrationHash[c.clientId] = c;
    }
    for (let x of clientList) {
      let integration = clientIntegrationHash[x.id];
      if (!integration) {
        continue;
      }
      let targetId = integration.targetId;
      if (!(targetId in this.targetClient)) {
        this.targetClient[targetId] = { name: '' };
      }
      if (this.targetClient[targetId].name) {
        this.targetClient[targetId].name += ", ";
      }
      this.targetClient[targetId].name += x.nickname;
    }

    this.targetList = await this.api.clientTransferTargetListAdministrable();
    for (let target of this.targetList) {
      let targetId = target.id;
      if (!(targetId in this.targetClient)) {
        this.targetClient[targetId] = { name: '–' };
      }
      target.name = target.name || "" + target.id;
    }
  }

  rowCall(key: string, row: ClientTransferTarget) {
    if (row.id) {
      this.router.navigateToRoute("bookkeeping/settings/target-edit", { id: "" + row.id });
    }
  }
}
