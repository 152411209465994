import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, MyHttpApi } from 'utils/api';
interface UIClientSource extends Client {
  transferHour: number;
  accountingTransferDelay: number;
  monthlyAccounting: boolean;
}

@autoinject
export class BookkeepingSettingsSourceList {
  private clientList: UIClientSource[] = [];
  private clients: { [key: number]: Client } = {};
  private businessGroupList: BusinessGroup[] = [];
  private businessGroups: { [key: number]: BusinessGroup } = {};
  private fieldList: FieldSpec[] = [
    { key: "id", header: "client-sale.client", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "transferHour", header: "client-sale.transferHour", type: "number" },
    { key: "accountingTransferDelay", header: "client-sale.accountingTransferDelay", type: "number" },
    { key: "monthlyAccounting", header: "client-sale.monthlyAccounting", type: "boolean" },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }
  
  async activate() {
    await this.refresh();
  }

  async refresh() {
    [this.clientList, this.businessGroupList] = await Promise.all([
      this.api.clientList(),
      this.api.businessGroupList(),
    ]);

    for (let x of this.businessGroupList) {
      this.businessGroups[x.id] = x;
    }

    for (let x of this.clientList) {
      this.clients[x.id] = { ...x, name: x.nickname, };
    }
    if (!this.api.session?.canIntegrationSettings) {
      this.clientList = this.clientList.filter(c => !!c.transferHour)
    }
  }

  rowCall(key: string, row: Client) {
    if (row.id) {
      this.router.navigateToRoute("bookkeeping/settings/source-edit", {id: ""+ row.id});
    }
  }
}
