import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { ClientTransferTargetMessage, IdName, MyHttpApi, ParamsType, strategyList, TransferredAccountingListResponse, TransferStatus } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

interface UITransferredAccountingListResponse extends TransferredAccountingListResponse {
  deleteCttm?: string;
}
@autoinject
export class ClientSaleLogAccounting extends BaseRoute {
  private logList: UITransferredAccountingListResponse[] = [];
  private clientList: IdName[] = [];
  private showCttm = false;
  private cttmToDelete?: TransferredAccountingListResponse;
  private cttm?: ClientTransferTargetMessage;
 
  private statusList = [
    { id: "OK", name: "OK" },
    { id: "ERROR", name: this.i18n.tr("accounting.ERROR") },
  ];
  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.accountingTransferredAccountingList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable( {changeHandler: "search"})
  private clientId?: number;
  @observable( {changeHandler: "search"})
  private status?: TransferStatus;
  @observable( {changeHandler: "search"})
  private startDate?: Date;
  @observable( {changeHandler: "search"})
  private endDate?: Date;
  @observable( {changeHandler: "search"})
  private deleted = false;

  protected override routeParams() {
    return {
      "clientId": Number,
      "status": String,
      "startDate": Date,
      "endDate": Date,
      "deleted": Boolean,
    };
  }
  private fieldList: FieldSpec[] = [
    { key: "businessDate", header: "common.date", type: "date", format: "DD.MM.YYYY" },
    { key: "locationName", header: "bookkeeping.location", type: "text", },
    { key: "accountingId", header: "bookkeeping.accountingNr", type: "number", },
    { key: "strategy", header: "bookkeeping.target", type: "lookup", lookupData: MyHttpApi.toHashStr(strategyList), lookupKey: "name", },
    { key: "shortStatus", header: "bookkeeping.status", type: "lookup", lookupData: MyHttpApi.toHashStr(this.statusList), lookupKey: "name", },
    { key: "finishTime", header: "bookkeeping.completedTime", type: "date" },
    { key: "attemptTime", header: "bookkeeping.transferAttempt", type: "date" },
    { key: "attemptCount", header: "bookkeeping.attemptCount", type: "number" },
    { key: "deleteTime", header: "bookkeeping.deleteTime", type: "date" },
    { key: "deleteCttm", header: "bookkeeping.delete", type: "text", cssClass: "link" },
  ];

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
    super();
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    if (!this.startDate) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1, 1);
      this.startDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1));
    }
    this.clientList = await this.api.clientListAllWithIntegration();
    await this.ecs.search();
  }

  // For base-route
  buildQueryParams() {
    return {
      clientId: this.clientId,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      deleted: this.deleted,
    };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: TransferredAccountingListResponse[]) {
    this.logList = data.map(x => {
      let ta: UITransferredAccountingListResponse = x;

      if(x.shortStatus == "ERROR" && !x.deleteTime) {
        ta.deleteCttm = this.i18n.tr("bookkeeping.delete");
      }

      return ta;
    });
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async rowCall(key: string, row: TransferredAccountingListResponse) {
    if(key === "deleteCttm") {
      this.deleteCttmStart(key, row);
    } else if (row.cttmId) {
      if (!this.cttm || this.cttm.id != row.cttmId) {
        this.cttm = await this.api.clientSaleBillingCttm({ id: row.cttmId });
      }
      this.showCttm = true;
    }
  }

  deleteCttmStart(key: string, row: TransferredAccountingListResponse) {
    // don't allow hiding if not invalid or already hidden
    if(row.shortStatus != 'ERROR' || row.deleteTime) {
      return;
    }
    this.cttmToDelete = row;
  }

  async deleteCttm() {
    if(!this.cttmToDelete) {
      return;
    }
    let cttmToDelete = this.cttmToDelete;
    this.cttmToDelete = undefined;
    await this.api.clientSaleBillingDeleteCttm({ id: cttmToDelete.cttmId });
    await this.search();
  }

  cancelDeleteCttm() {
    this.cttmToDelete = undefined;
  }
}
