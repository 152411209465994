/* eslint-disable @typescript-eslint/ban-types */
import { I18N } from "aurelia-i18n";
import { IntegrationMessageExtended, IntegrationMessageMissingAccounts, PosPaymentType } from "utils/api";

export function missingAccountsToMessage(i18n: I18N, missingAccounts?: IntegrationMessageMissingAccounts) {
  let message = "";
  if (!missingAccounts) {
    return message;
  }
  message += missingVatsToMessage(i18n, missingAccounts.vats);
  if (missingAccounts.vats.length) {
    message += "\n";
  }

  message += missingPaymentTypesToMessage(i18n, missingAccounts.paymentTypes);
  if (missingAccounts.paymentTypes.length) {
    message += "\n\n";
  }

  message += missingProductCategoriesToMessage(i18n, missingAccounts.productCategories);
  if (missingAccounts.productCategories.length) {
    message += "\n\n";
  }

  message += missingProductIdsToMessage(i18n, missingAccounts.productIds);
  if (missingAccounts.productIds) {
    message += "\n\n";
  }

  message += missingGiftCardNkAccountsToMessage(i18n, missingAccounts.giftCardNkAccounts);
  return message;
}

export function missingVatsToMessage(i18n: I18N, list: number[]) {
  return list
    .map(item =>
      i18n.tr("integration-message.missingAccounts-vats", { item } as {})
    ).join("\n");
}

export function missingProductIdsToMessage(i18n: I18N, list: string[]) {
  return list
    .map(item =>
      i18n.tr("integration-message.missingAccounts-productIds", { item } as {})
    ).join("\n");
}

export function missingPaymentTypesToMessage(i18n: I18N, list: PosPaymentType[]) {
  return list
    .map(item => {
      return i18n.tr("integration-message.missingAccounts-paymentTypes", { item: i18n.tr("PaymentType." + item) } as {});
    }).join("\n");
}

export function missingProductCategoriesToMessage(i18n: I18N, list: string[]) {
  return list
    .map(item =>
      i18n.tr("integration-message.missingAccounts-productCategories", { item } as {})
    ).join("\n");
}

export function missingGiftCardNkAccountsToMessage(i18n: I18N, list: string[]) {
  return list
    .map(item =>
      i18n.tr("integration-message.missingAccounts-giftCardNkAccounts", { item } as {})
    ).join("\n");
}

/**
 * Combined all memo.missingAccounts to one, which is unique over all and displays these issues as translated string
 * @param memos List of IntegrationMessages
 */
export function combineMissingAccounts(memos: IntegrationMessageExtended[]) {
  let missingAccounts: IntegrationMessageMissingAccounts = {
    integrationMessageId: 0,
    vats: [],
    paymentTypes: [],
    productCategories: [],
    productIds: [],
    giftCardNkAccounts: [],
  };
  memos.forEach(memo => {
    const ma = memo.missingAccounts;
    if (!ma) {
      return;
    }

    ma.vats.forEach(x => {
      if (!missingAccounts.vats.includes(x)) {
        missingAccounts.vats.push(x);
      }
    });

    ma.paymentTypes.forEach(x => {
      if (!missingAccounts.paymentTypes.includes(x)) {
        missingAccounts.paymentTypes.push(x);
      }
    });

    ma.productCategories.forEach(x => {
      if (!missingAccounts.productCategories.includes(x)) {
        missingAccounts.productCategories.push(x);
      }
    });

    ma.productIds.forEach(x => {
      if (!missingAccounts.productIds.includes(x)) {
        missingAccounts.productIds.push(x);
      }
    });

    ma.giftCardNkAccounts.forEach(x => {
      if (!missingAccounts.giftCardNkAccounts.includes(x)) {
        missingAccounts.giftCardNkAccounts.push(x);
      }
    });
  });
  return missingAccounts;
}
