import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, GiftCardEventType, GiftCardProduct, GiftCardSystem, GiftCardUsageResponse, IdName, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class BookkeepingReportGiftCardsUsage extends BaseRoute {
  private clientList: Client[]= [];
  private businessGroupList: BusinessGroup[]= [];
  private businessGroups: { [key: number]: BusinessGroup; } = {};
  private clients: { [key: number]: IdName; } = {};
  private products: { [key: number]: GiftCardProduct; } = {};
  private systemList: { id: string, name: string; }[] = [
    { id: "POS", name: this.i18n.tr("GiftCardSystem.POS") },
    { id: "DASHBOARD", name: this.i18n.tr("GiftCardSystem.DASHBOARD") },
    { id: "NETSTORE", name: this.i18n.tr("GiftCardSystem.NETSTORE") },
  ];

  private eventList: { id: string, name: string; }[] = [
    { id: "USE", name: this.i18n.tr("GiftCardEvent.USE") },
    { id: "EXPIRE", name: this.i18n.tr("GiftCardEvent.EXPIRE") },
  ];

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "type", header: "giftCard.event", type: "enum", enum: "GiftCardEvent" },
    { key: "reference", header: "common.reference", type: "text", },
    { key: "number", header: "giftCard.number", type: "text", },
    { key: "purchaserEmail", header: "emailTemplate.GiftCardEmailDeliveryPurchaserEmail", type: "text" },
    { key: "value", header: "GiftCardEvent.USE", type: "number",  minimumFractionDigits: 2},
    { key: "purchasedAtSystem", header: "giftCard.purchasedAtSystem", type: "enum", enum: "GiftCardSystem" },
    { key: "createTime", header: "giftCard.useTime", type: "date", format: "DD.MM.YYYY HH:MM" },
    { key: "businessGroupId", header: "giftCard.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "purchasedAtClientId", header: "giftCard.purchasedAtClientId", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "clientId", header: "giftCard.usedAtClientId", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "productName", header: "giftCard.productNameText", type: "text" },
  ];

  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.reportGiftCardUsage(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  private usageList: GiftCardUsageResponse[] = [];

  @observable({ changeHandler: "search" })
  private businessGroupId?: number;
  @observable({ changeHandler: "search" })
  private type?: GiftCardEventType;
  @observable({ changeHandler: "search" })
  private purchasedAtClientId?: number;
  @observable({ changeHandler: "search" })
  private usedAtClientId?: number;
  @observable({ changeHandler: "search" })
  private system?: GiftCardSystem;
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private productName?: string;
  @observable({ changeHandler: "search" })
  private email?: string;

  protected override routeParams() {
    return {
      "purchasedAtClientId": Number,
      "type": String,
      "usedAtClientId": Number,
      "businessGroupId": Number,
      "system": String,
      "startDate": Date,
      "endDate": Date,
      "productName": String,
      "email": String,
    };
  }

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
    super();
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    let [clientList, businessGroupList, productList] = await Promise.all([
      this.api.clientList(),
      this.api.businessGroupList(),
      this.api.giftCardProductList({undeletedOnly: false,}),
    ]);
    for (let c of clientList) {
      this.clients[c.id] = { id: c.id, name: c.nickname };
    }
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    for (let p of productList) {
      this.products[p.id] = p;
    }
    this.clientList = clientList;
    this.businessGroupList = businessGroupList;
    await this.ecs.search();
  }

  buildQueryParams() {
    return {
      type: this.type,
      purchasedAtClientId: this.purchasedAtClientId,
      usedAtClientId: this.usedAtClientId,
      businessGroupId: this.businessGroupId,
      system: this.system,
      startDate: this.startDate,
      endDate: this.endDate,
      productName: this.productName,
      email: this.email,
    };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: GiftCardUsageResponse[]) {
    this.usageList = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async rowCall(key: string, row: GiftCardUsageResponse) {
    if (row.giftCardId) {
      this.router.navigateToRoute("gift-cards/show", { id: "" + row.giftCardId });
    }
  }

  @computedFrom("usageList")
  get total() {
    return this.usageList.map(p => p.value).reduce((a, b) => a + b, 0);
  }

  @computedFrom("businessGroupId")
  get clientListDynamic() {
    if (this.businessGroupId) {
      return this.clientList.filter(x => x.businessGroupId === this.businessGroupId);
    }
    return this.clientList.filter(x => x.businessGroupId);
  }
}
