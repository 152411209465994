import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, ClientIntegration, ClientTransferTarget, MyHttpApi, strategyList } from 'utils/api';

interface UIClientIntegration extends Client {
  targetId?: number;
  targetName?: string;
  strategy?: string;
}

@autoinject
export class BookkeepingSettingsClientIntegrationList {
  private clientIntegrationList: ClientIntegration[] = []
  private targetList: ClientTransferTarget[] = []
  private clientList: UIClientIntegration[] = []
  private targets: { [key: number]: ClientTransferTarget } = {};
  private strategy: { [key: string]: {name : string} } = {};
  private clients: { [key: number]: Client } = {};
  private fieldList: FieldSpec[] = [
    { key: "id", header: "unit.unit", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "targetName", header: "client-sale.integration", type: "lookup", lookupData: this.targets, lookupKey: "targetId", },
    { key: "strategy", header: "client-sale.integrationType", type: "lookup", lookupData: this.strategy, lookupKey: "name", },
  ];
  
  constructor(private api: MyHttpApi, private router: Router) {
    this.refresh();
    for (let s of strategyList) {
      this.strategy[s.id] = s;
    }
  }

  async refresh() {
    [this.clientIntegrationList, this.clientList, this.targetList] = await Promise.all([
      this.api.clientIntegrationList(), 
      this.api.clientList(),
      this.api.clientTransferTargetListSelectable({}),
    ])
    for (let t of this.targetList) {
      this.targets[t.id] = t;
    }
    
    for (let x of this.clientList) {
      this.clients[x.id] = { ...x, name: x.nickname, };
      let integration = this.clientIntegrationList.find(ci => x.id === ci.clientId);
      let target = this.targetList.find(t => integration?.targetId === t.id);
      x.targetId = target?.id;
      x.targetName = target?.name;
      x.strategy = target?.strategy;
    }
  }
  rowCall(key: string, row: Client) {
    if (row.id) {
      this.router.navigateToRoute("bookkeeping/settings/client-integration-edit", {id: ""+ row.id});
    }
  }
}
