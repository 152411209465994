import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BookkeepingCustomerDiff, ClientTransferTarget, MyHttpApi } from 'utils/api';

interface UIBookkeepingCustomerDiff extends BookkeepingCustomerDiff {  
  id?: number;
  name?: string;
  name2?: string;
  officialName?: string;
  businessId?: string;
  streetAddressAndZip?: string;
  paymentTermDays?: number;
  invoiceChannel?: string;
  invoiceOperator?: string;
  invoiceAddress?: string;
  apiDupe?: string;
  dbDupe?: string;
  dupeId?: number;
  dupeName?: string;
  dupeName2?: string;
  dupeOfficialName?: string;
  dupeBusinessId?: string;
  dupeStreetAddressAndZip?: string;
  dupePaymentTermDays?: number;
  dupeInvoiceChannel?: string;
  dupeInvoiceOperator?: string;
  dupeInvoiceAddress?: string;
  matchingCols?: string;
  nonMatchingCols?: string;
  invoiceInfoMatches?: boolean;
}

@autoinject
export class BookkeepingSettingsBookkeepingCustomerDiff {
  private target?: ClientTransferTarget;
  private content: UIBookkeepingCustomerDiff[] = [];
  private targetId = 0;
  private fields: FieldSpec[] = [
    { cssClass: "not-clickable", key: "source", header: "customer.source", type: "text", },
    { cssClass: "not-clickable", key: "id", header: "ID", type: "text", },
    { cssClass: "not-clickable", key: "dbDupe", header: "customer.dbDube", type: "text", },
    { cssClass: "not-clickable", key: "apiDupe", header: "customer.apiDube", type: "text", },
    { cssClass: "not-clickable", key: "name", header: "common.name", type: "text" },
    { cssClass: "not-clickable", key: "dupeName", header: "customer.nameDupe", type: "text" },
    { cssClass: "not-clickable", key: "name2", header: "customer.name2", type: "text" },
    { cssClass: "not-clickable", key: "dupeName2", header: "customer.name2Dupe", type: "text" },
    { cssClass: "not-clickable", key: "officialName", header: "client-billing.officialName", type: "text" },
    { cssClass: "not-clickable", key: "dupeOfficialName", header: "customer.officialNameDupe", type: "text" },
    { cssClass: "not-clickable", key: "businessId", header: "client-billing.vatId", type: "text" },
    { cssClass: "not-clickable", key: "dupeBusinessId", header: "customer.vatIdDupe", type: "text" },
    { cssClass: "not-clickable", key: "streetAddressAndZip", header: "customer.address", type: "text" },
    { cssClass: "not-clickable", key: "dupeStreetAddressAndZip", header: "customer.addressDupe", type: "text" },
    { cssClass: "not-clickable", key: "paymentTermDays", header: "customer.paymentTerms", type: "text" },
    { cssClass: "not-clickable", key: "dupePaymentTermDays", header: "customer.paymentTermsDupe", type: "text" },
    { cssClass: "not-clickable", key: "invoiceChannel", header: "client-billing.invoiceChannel", type: "text" },
    { cssClass: "not-clickable", key: "dupeInvoiceChannel", header: "customer.invoiceChannelDupe", type: "text" },
    { cssClass: "not-clickable", key: "invoiceOperator", header: "customer.invoiceOperator", type: "text" },
    { cssClass: "not-clickable", key: "dupeInvoiceOperator", header: "customer.invoiceOperatorDupe", type: "text" },
    { cssClass: "not-clickable", key: "invoiceAddress", header: "customer.invoiceAddress", type: "text" },
    { cssClass: "not-clickable", key: "dupeInvoiceAddress", header: "customer.invoiceAddressDupe", type: "text" },
    { cssClass: "not-clickable", key: "matchingCols", header: "customer.matchingCols", type: "text" },
    { cssClass: "not-clickable", key: "nonMatchingCols", header: "customer.nonMatchingCols", type: "text" },
    { cssClass: "not-clickable", key: "invoiceInfoMatches", header: "customer.invoiceInfoMatches", type: "boolean" },
  ];

  constructor(private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    if (params.id) {
      this.target = await this.api.clientTransferTargetById({ id: parseInt(params.id) });
        this.targetId = this.target.id;
        let data = await this.api.bookkeepingCustomerApiVsDbDiff({ targetId: this.target.id });
        this.content = this.handleContent(data);
    }
  }

  handleContent(data: BookkeepingCustomerDiff[]) {
    return data.map(x => {
      let row: UIBookkeepingCustomerDiff = { ...x };
      row.id = x.customer.bookkeepingId;
      if (x.apiDublicateCustomer) {
        row.apiDupe = x.apiDuplicateMatchLevel + " API-" + x.apiDublicateCustomer?.bookkeepingId;
      }
      if (x.dbDublicateCustomer) {
        row.dbDupe = x.dbDuplicateMatchLevel + " DB-" + x.dbDublicateCustomer?.bookkeepingId;
      }
      row.name = x.customer.name;
      row.name2 = x.customer.additionalName;
      row.officialName = x.customer.officialName;
      row.businessId = x.customer.businessId;
      row.streetAddressAndZip = x.customer.streetAddress + " " + x.customer.zip;
      row.paymentTermDays = x.customer.paymentTermDays;
      row.invoiceChannel = x.customer.invoiceChannel;
      row.invoiceOperator = x.customer.invoiceOperator;
      row.invoiceAddress = x.customer.invoiceAddress;
      row.id = x.customer.bookkeepingId;
      row.dupeName = x.differentSourceDublicateCustomer?.name;
      row.dupeName2 = x.differentSourceDublicateCustomer?.additionalName;
      row.dupeOfficialName = x.differentSourceDublicateCustomer?.officialName;
      row.dupeBusinessId = x.differentSourceDublicateCustomer?.businessId;
      row.dupeStreetAddressAndZip = (x.differentSourceDublicateCustomer?.streetAddress || "") + " " + (x.differentSourceDublicateCustomer?.zip || "");
      row.dupePaymentTermDays = x.differentSourceDublicateCustomer?.paymentTermDays;
      row.dupeInvoiceChannel = x.differentSourceDublicateCustomer?.invoiceChannel;
      row.dupeInvoiceOperator = x.differentSourceDublicateCustomer?.invoiceOperator;
      row.dupeInvoiceAddress = x.differentSourceDublicateCustomer?.invoiceAddress;
      row.matchingCols = x.compareResult.matchingColumns.join(", ");
      row.nonMatchingCols = x.compareResult.nonMatchingColumns.join(", ");
      row.invoiceInfoMatches = x.compareResult.invoiceInfoMatches;
      return row;
    });
  }

  @computedFrom("targetId", "i18n.i18next.language")
  get excelUrl() {
    return this.api.bookkeepingCustomerApiVsDbDiffExcelUrl({targetId: this.targetId, lang: this.i18n.getLocale()});
  }
}
