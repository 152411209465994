import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FormatDateValueConverter } from 'resources/value-converters/format-date';
import { FormatMoneyValueConverter } from 'resources/value-converters/format-money';
import { BookkeepingAuditMemoResponse, Client, MyHttpApi, ParamsType } from 'utils/api';
import { DatesToRange } from 'utils/dates-to-range';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class BillingCombinedBilling extends BaseRoute {
  private clientList: Client[] = [];
  private locationNameList: { id: string, name: string; }[] = [];
  private memo?: BookkeepingAuditMemoResponse;
  private formatMoney = new FormatMoneyValueConverter;
  private formatDate = new FormatDateValueConverter;
  private hiddenSalesInvoiceSum?: string;
  private hasGiftCards = false; // Show giftCardDebt section only, if customer has any settings
  private salesInvoiceParams: ParamsType = {};
  private salesInvoiceParamsDeleted: ParamsType = {};
  private combinedBillingParams: ParamsType = {};

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.reportAuditMemo(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "reloadLocationsAndBusinessGroup" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private locationName?: string;
  @observable({ changeHandler: 'search' })
  private year: number = this.getYear();
  @observable({ changeHandler: 'search' })
  private month: number = this.getLastMonth();

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private datesToRange: DatesToRange) {
    super();
  }

  lastMonth() {
    let d = new Date();
    d.setMonth(d.getMonth() - 1, 1);
    return d;
  }

  getLastMonth() {
    let d = this.lastMonth();
    return (d.getMonth() + 1);
  }

  getYear() {
    let d = this.lastMonth();
    return d.getFullYear();
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "locationName": String,
      "year": Number,
      "month": Number,
    };
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    [this.clientList] = await Promise.all([
      this.api.clientList(),
    ]);
    if (this.clientList.length == 1) {
      this.clientId = this.clientList[0].id;
    }
  }

  buildQueryParams() {
    return {
      clientId: this.clientId || 0,
      locationName: this.locationName,
      year: this.year,
      month: this.month || 1, // this will crash if not defaulted. Would be better, if we did not search at all with undefined
    };
  }

  async reloadLocationsAndBusinessGroup() {
    if (!this.clientId) {
      this.memo = undefined;
      super.rewriteWindowUrl(this.buildQueryParams());
      return;
    }
    let [list, hasGiftCards] = await Promise.all([
      this.api.clientLocationNames({ clientId: this.clientId }),
      this.api.giftCardSettingsHasSettingsByClientId({ clientId: this.clientId }),
    ]);
    this.locationNameList = list.map(l => {
      return { id: l, name: l };
    });
    this.hasGiftCards = hasGiftCards;
    await this.search();
  }

  async search() {
    if (!this.clientId) {
      this.memo = undefined;
      return;
    }
    await this.ecs.search();
  }

  applyData(data: BookkeepingAuditMemoResponse) {
    this.memo = data;
    this.setSearchParams();
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  /** Set all 4 link params. Due deleted, we have twice almost identical params */
  setSearchParams() {
    let clientId = this.clientId;
    let year = this.year;
    let month = this.month;

    this.salesInvoiceParams = BaseRoute.objectToUrlParams({
      clientId, deleted: false,
    });
    this.salesInvoiceParamsDeleted = BaseRoute.objectToUrlParams({
      clientId, deleted: true,
    });
    this.combinedBillingParams = BaseRoute.objectToUrlParams({
      clientId, month, year
    });
  }

  @computedFrom("memo.missingAccountingsDates", "memo.hiddenSalesInvoiceSum", "memo.unsentSalesInvoices", "memo.unsentCombinedBilling")
  get memoHasErrors() {
    if (!this.memo) {
      return false;
    }
    this.hiddenSalesInvoiceSum = this.formatMoney.toView(this.memo.hiddenSalesInvoiceSum);
    return this.memo.missingAccountingsDates.length || this.memo.hiddenSalesInvoiceSum || this.memo.unsentSalesInvoices
      || this.memo.unsentCombinedBilling;
  }

  @computedFrom("memo.missingAccountingsDates")
  get missingAccountingDatesString() {
    if (!this.memo) {
      return "";
    }
    return this.datesToRange.transform([...this.memo?.missingAccountingsDates || []]);
  }

  @computedFrom("memo.salesAndPaymentTypes.salesTotal", "memo.salesAndPaymentTypes.paymentTypesTotal", "memo.expenses.total", "memo.giftCards.total", "memo.giftCardDebt.creditTotal", "memo.giftCardDebt.debitTotal")
  get memoHasData() {
    let memo = this.memo;
    if (!memo) {
      return false;
    }
    return memo.salesAndPaymentTypes.salesTotal > 0 || memo.salesAndPaymentTypes.paymentTypesTotal > 0 || memo.expenses.total > 0 || memo.giftCards.total > 0 || memo.giftCardDebt.creditTotal > 0 || memo.giftCardDebt.debitTotal > 0;
  }

  @computedFrom("clientId", "locationName", "year", "month", "i18n.i18next.language")
  get excelUrl() {
    return this.api.reportAuditMemoExcelUrl({...this.buildQueryParams(), lang: this.i18n.getLocale()});
  }
}
